<template>
    <v-card class="d-flex align-center" style="place-content: center">
        <v-app-bar
                dark
                fixed
        >
            <v-btn icon
                   dark
                   data-dismiss="modal"
                   @click="detailClose"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>기프티콘 정보</v-toolbar-title>
            <v-spacer></v-spacer>

        </v-app-bar>

        <v-sheet id="scrolling-techniques-6"
                class="overflow-y-auto"
        >
            <v-container style="margin-top: 60px;">

                <v-col cols="12">

                    <div class="d-flex justify-center align-center">
                        <div class="text-center">
                            <img :src="gift_data.product_img"
                                 width="180px"
                                 height="fit-content"
                            />
                            <h4 class="mt-3">{{ gift_data.product_name }}</h4>
                            <h2 class="display-1 font-weight-bold mt-2 mb-8"> {{ formatPrice(gift_data.sale_price) }}</h2>

                            <v-divider class="ml-3 mr-3"></v-divider>

                             <p class="mt-8 detail_info">
                                {{ gift_data.product_description }}
                            </p>

                            <div class="d-flex">
                                <v-col cols="10">
                                    <v-btn class="mx-1"
                                           v-if="deposit_type == false"
                                           block
                                           depressed
                                           color="#5EB4F9"
                                           :dark="gift_data.product_status == '판매' ? true: false"
                                           @click="buyAdapter"
                                           :disabled="gift_data.product_status == '판매' ? false: true"
                                    >
                                        {{ gift_data.product_status=='판매' ? '구매하기': '판매된 상품입니다.' }}
                                    </v-btn>

                                    <v-btn class="mx-1" v-else
                                           block
                                           depressed
                                           disalbed
                                    >
                                        출금신청 중이라 구매하실 수 없습니다.
                                    </v-btn>

                                </v-col>

                                <v-col cols="2">
                                    <v-btn icon
                                           :color="favorite==true?'#ff0000': 'grey'"
                                           @click="updateFavorite()"
                                    >
                                        <v-icon>favorite</v-icon>
                                    </v-btn>
                                </v-col>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-container>
        </v-sheet>

        <v-dialog
                v-model="fill_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    충전 하시겠습니까?
                </v-card-title>
                <v-card-text>충전 금액이 필요합니다.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="grey"
                            text
                            @click="fill_dialog = false"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            color="#5EB4F9"
                            text
                            @click="fillMoney"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-model="buy_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    구매 하시겠습니까?
                </v-card-title>
                <v-card-text> 해당 상품을 구매하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="grey"
                            text
                            @click="buy_dialog = false"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            color="#5EB4F9"
                            text
                            @click="buyProduct"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-model="result_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{result_data.title}}
                </v-card-title>
                <v-card-text> {{result_data.content}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="#5EB4F9"
                            text
                            @click="result_dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-model="login_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    로그인이 필요합니다.
                </v-card-title>
                <v-card-text> 로그인 하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="grey"
                            text
                            @click="login_dialog = false"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            color="#5EB4F9"
                            text
                            @click="goLogin"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar">
            {{ snackbar_msg }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="closePage"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>


        <v-overlay :value="loading_bar">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-card>
    
</template>

<script>
    import axios from '../../service/axios'

    export default {
        name: "GiftDetailModal",
        data() {
            return{
                id: null,
                favorite: null,
                favorite_id: null,
                gift_data: {
                    brand_id: null,
                    brand_img: null,
                    brand_name: null,
                    category_id: null,
                    category_name: null,
                    create_date: null,
                    create_time: null,
                    favorite: null,
                    favorite_id: null,
                    org_price: null,
                    product_code: null,
                    product_img: null,
                    product_name: null,
                    product_status: null,
                    product_type: null,
                    product_valid_time_end: null,
                    product_valid_time_start: null,
                    sale_price: null,
                    _id: null
                },
                update_yn: false,
                user_cash_info: {},
                fill_dialog:false,
                buy_dialog: false,
                result_dialog: false,
                result_data: {
                    title: null,
                    content: null
                },
                deposit_type: false,
                loading_bar: false,
                snackbar: false,
                snackbar_msg: null,
                login_dialog: false,
            }
        },

        props: {
            item: {
                type: Object,
                require: false,
            },
        },

        watch: {
            // item(){
            //     this.depositType();
            //     this.change_data();
            //     this.giftDetailInfo();
            // },
        },

        created () {
            console.log('this.item ......... : ', this.item)
            this.gift_data = this.item 
            // this.setPage(this.item)
            // this.depositType();
            // this.change_data();
            // this.giftDetailInfo();
        },

        methods: {
            // setPage(item) {

            // },

            initgiftData(){
                this.id = null;
                this.favorite = null;
                this.favorite_id = null;
                this.gift_data = {
                    brand_id: null,
                    brand_img: null,
                    brand_name: null,
                    category_id: null,
                    category_name: null,
                    create_date: null,
                    create_time: null,
                    favorite: null,
                    favorite_id: null,
                    org_price: null,
                    product_code: null,
                    product_img: null,
                    product_name: null,
                    product_status: null,
                    product_type: null,
                    product_valid_time_end: null,
                    product_valid_time_start: null,
                    sale_price: null,
                    _id: null,
                    expire_yn: false,
                };
            },

            change_data(){
                this.update_yn = false;
                if(this.$props.item != null){
                    this.id = this.$props.item._id;
                    this.favorite = this.$props.item.favorite;
                    this.favorite_id = this.$props.item.favorite_id;
                }
            },

            giftDetailInfo(){
                if(this.id != null) {
                    const params = {
                        accessKey: '',
                        options: this.id
                    }
                    axios.post('/api/product/detail', params).then(res => {
                        this.loading_bar = false;
                        this.gift_data = res.data.data;
                    }).catch(error => {
                        this.loading_bar = false;
                        console.log('페스트 오류', error)
                    });
                }
            },
            
            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'원';
                }
                catch{
                    return value+'원';
                }
            },

            detailClose(){
                this.initgiftData();
                this.$emit('hide', this.update_yn);
            },

            updateFavorite(){
                if(this.$cookies.get('token') != null){
                    const fav = this.favorite;
                    this.favorite = !this.favorite;
                    if(fav == true){
                        this.delFavorite();
                    }else{
                        this.addFavorite();
                    }
                }else{
                    this.snackbar_msg = '로그인 전에는 관심 기프티콘을 등록하실 수 없습니다.'
                    this.snackbar = true;
                }
            },

            addFavorite(){
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: this.$props.item
                }
                axios.post('/api/product/favoriteCreate', params).then(res =>{
                    console.log(res.data.data)
                    this.update_yn = true;
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
            delFavorite(){
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: this.$props.item.favorite_id
                }
                axios.post('/api/product/favoriteDelete', params).then(res =>{
                    console.log(res.data.data)
                    this.update_yn = true;
                    this.$emit('delete-event');
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
            async buyAdapter(){
                this.result_data.title = '안내';
                this.result_data.content = '서비스 준비중입니다';
                this.result_dialog = true;

                console.log('access token', this.$cookies.get('token'));

                // if(this.$cookies.get('token') != null){
                //     this.loading_bar = true;
                //     await this.expireToken();
                //     if(this.expire_yn == true){
                //         await this.walletInfo();
                //         if(this.user_cash_info.cash > this.gift_data.sale_price){
                //             this.loading_bar = false;
                //             this.buy_dialog = true;
                //         }else{
                //             this.loading_bar = false;
                //             this.fill_dialog = true;
                //         }
                //     }else{
                //         this.loading_bar = false;
                //         this.login_dialog = true;
                //     }
                // }else{
                //     this.result_data.title = '로그인 후 이용해주세요.';
                //     this.result_data.content = '로그인 후 이용하실 수 있습니다.';
                //     this.result_dialog = true;
                // }
            },
            async walletInfo(){
                const params = {
                    accessKey: this.$cookies.get('token'),
                }
                await axios.post('/api/payment/walletInfo', params).then(res =>{
                    this.user_cash_info = res.data.data;
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
            buyProduct(){
                this.buy_dialog = false;
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: this.gift_data,
                    type: '구매요청'
                }
                axios.post('/api/payment/createPayment', params).then(res =>{
                    this.result_data.title = res.data.data.title;
                    this.result_data.content = res.data.data.content;
                    var payment_id = res.data.data.payment_id;
                    var user_name = res.data.data.user_name;

                    this.giftDetailInfo();
                    // this.update_yn = true;
                    // this.result_dialog = true;

                    console.log('기프티콘 데이터', this.gift_data);
                    // var product_id = this.gift_data._id;
                    var product_name = this.gift_data.product_name;
                    var product_price = this.gift_data.org_price;
                    
                    var order_name = `${product_name} ${product_price}`;
                    var order_id = payment_id;

                    console.log('구매데이터', res.data);

                    console.log(`주문번호 : ${order_id}, 주문명 : ${order_name}, 주문가격 : ${product_price}, 사용자명 : ${user_name}`)

                    var clientKey = 'test_ck_d26DlbXAaV021bYM5Db8qY50Q9RB';
                    var tossPayments = window.TossPayments(clientKey);
                    // console.log(tossPayments);

                    tossPayments.requestPayment('카드', {
                        amount: product_price,
                        orderId: order_id,
                        orderName: order_name,
                        customerName: user_name,
                        successUrl: window.location.origin + '/success',
                        failUrl: window.location.origin + '/fail',
                    });

                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
            fillMoney(){
                this.$router.push('/payments/AddMoney');
                // this.fill_dialog = false;
                // const payment_info = {
                //     deposit_ammount: 11000,
                //     deposit_type: '입금'
                // };
                // const params = {
                //     accessKey: this.$cookies.get('token'),
                //     options: payment_info
                // }
                // axios.post('/api/payment/createDepositCharge', params).then(res =>{
                //     this.result_data.title = res.data.data.title;
                //     this.result_data.content = res.data.data.content;
                //     this.result_dialog = true;
                // }).catch(error=>{
                //     console.log('페스트 오류', error)
                // });
            },
            closePage(){
                this.snackbar = false;
            },

            async depositType(){
                let accessKey = this.$cookies.get('token');
                if(accessKey != null){
                    this.loading_bar = true;
                    let options = {
                        deposit_type: '출금신청'
                    }
                    const params = {
                        accessKey: this.$cookies.get('token'),
                        options: options
                    }
                    console.log(params);

                    await axios.post('/api/payment/depositTypeInfo', params).then(res =>{
                        let data = res.data.data;
                        if(data != null){
                            this.deposit_type = true;
                        }else{
                            this.deposit_type = false
                        }
                    }).catch(error=>{
                        this.loading_bar = false;
                        console.log('페스트 오류', error)
                    });
                }

            },
            async expireToken(){
                const params = {
                    accessKey: this.$cookies.get('token'),
                }
                await axios.post('/api/auth/tokenInfo', params).then(res =>{
                    this.expire_yn = res.data._yn;
                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });
            },
            goLogin(){
                this.login_dialog = false;
                this.$cookies.remove('token');
                this.$router.push('/sign/login');
            }
        }
    }
</script>

<style lang="scss">
    .detail_info{
        font-size: 0.75rem;
        letter-spacing: -0.03em;
        line-height: 1.4;
    }
</style>

<style lang="scss" scoped>
    .v-toolbar__title{
        font-size: 1rem !important;
        color: #ffffff;
    }
</style>
